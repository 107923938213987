/**
 *
 * /TopBar
 *
 */

import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import emotion_styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import { drawerWidth, NavigationContext } from 'components/App'

import { useCurrentUser } from 'currentUserContext'

import logo_url from './logo.png'

const pages = ['Products', 'Tickets', 'Orders']
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']

const Logo = emotion_styled.img`
	max-height: 100%;
	max-width: 100%;
	height: 75px;
	padding: 0.5em;
`

const TopBar = ({ open, setOpen, ...props }) => {
	const [show_dropdown, setShowDropdown] = useState(false)
	const [anchorElNav, setAnchorElNav] = useState(null)
	const [anchorElUser, setAnchorElUser] = useState(null)

	const current_user = useCurrentUser()

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget)
	}
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	const handleCloseUserMenu = () => {
		setAnchorElUser(null)
	}

	return (
		<AppBar position="fixed" open={open} {...props}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={() => setOpen(!open)}
					edge="start"
					sx={{ mr: 2, ...(open && { display: 'none' }) }}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
					<Logo src={logo_url} alt="Holiday Matsuri" />
				</Typography>
				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: 'block', md: 'none' },
						}}
					>
						{pages.map((page) => (
							<MenuItem key={page} onClick={handleCloseNavMenu}>
								<Typography textAlign="center">
									<Link to={`/${page.toLowerCase()}`}>{page}</Link>
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Box>
				<Typography
					variant="h6"
					noWrap
					component="div"
					sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
				>
					LOGO
				</Typography>
				<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
					{pages.map((page) => (
						<Button
							key={page}
							onClick={() => setOpen(false)}
							sx={{ my: 2, color: 'white', display: 'block' }}
						>
							<Link to={`/${page.toLowerCase()}`}>{page}</Link>
						</Button>
					))}
				</Box>

				<Box sx={{ flexGrow: 0 }}>
					<Tooltip title="Open settings">
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							<Avatar alt={current_user.full_name}>{current_user.initials}</Avatar>
						</IconButton>
					</Tooltip>
					<Menu
						sx={{ mt: '45px' }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						<MenuItem onClick={handleCloseNavMenu}>
							<Typography textAlign="center">
								<Link to={`/users/${current_user.id}`}>Profile</Link>
							</Typography>
						</MenuItem>
						{settings.map((setting) => (
							<MenuItem key={setting} onClick={handleCloseNavMenu}>
								<Typography textAlign="center">
									<Link to={`/${setting}`}>{setting}</Link>
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	)
}

const StyledTopBar = styled(TopBar)(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

TopBar.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
}

export default memo(StyledTopBar)
