/**
 *
 * /SideBar
 *
 */

import React, { memo, useRef, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components';
import { styled, useTheme } from '@mui/material/styles'
import { NavLink, Link, useLocation } from 'react-router-dom'

import Logo from 'images/HolMat2020_Logo_long_white.png'

import {
	Apple,
	BrandAirtable,
	BuildingStore,
	Businessplan,
	CreditCard,
	Notes,
	Discount,
	Palette,
	PresentationAnalytics,
	Settings,
	Ticket,
	UserExclamation,
	Users,
	ExternalLink,
} from 'tabler-icons-react'

import Group from 'components/SideBar/Group'
import Item from 'components/SideBar/Item'

import { drawerWidth } from 'components/App'

import { useCurrentUser } from 'currentUserContext'
import usePermissionCheck from 'hooks/usePermissionCheck'

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}))

const SideBar = ({ open, setOpen, can_index }) => {
	const theme = useTheme()

	const { pathname } = useLocation()

	const current_user = useCurrentUser()

	// const can_index = {
	// 	products: usePermissionCheck(current_user.id, 'index_products', 'User'),
	// 	tickets: usePermissionCheck(current_user.id, 'index_tickets', 'User'),
	// 	orders: usePermissionCheck(current_user.id, 'index_orders', 'User'),
	// 	artists: usePermissionCheck(current_user.id, 'index_artists', 'User'),
	// 	guests: usePermissionCheck(current_user.id, 'index_guests', 'User'),
	// 	vendors: usePermissionCheck(current_user.id, 'index_vendors', 'User'),
	// 	meals: usePermissionCheck(current_user.id, 'index_meals', 'User'),
	// 	users: usePermissionCheck(current_user.id, 'index_users', 'User'),
	// 	settings: usePermissionCheck(current_user.id, 'index_settings', 'User'),
	// }

	const pages = useMemo(
		() =>
			[
				{
					permission: 'products',
					Icon: Ticket,
					label: 'Products',
					url: '/products',
					children: [
						,
						{
							label: 'Index',
							url: '/products',
						},
					],
				},
				// {
				// 	permission: 'tickets',
				// 	Icon: Ticket,
				// 	label: 'Tickets',
				// 	url: '/tickets',
				// 	children: [
				// 		{
				// 			label: 'Index',
				// 			url: '/tickets',
				// 		},
				// 	],
				// },
				{
					permission: 'merchandises',
					Icon: BuildingStore,
					label: 'Merchandise',
					url: '/merchandises',
					children: [
						{
							label: 'Index',
							url: '/merchandises',
						},
					],
				},
				{
					permission: 'coupons',
					Icon: Discount,
					label: 'Coupons',
					url: '/coupons',
					children: [
						{
							label: 'Index',
							url: '/coupons',
						}
					]
				},
				{
					permission: 'orders',
					Icon: Businessplan,
					label: 'Orders',
					url: '/orders',
					children: [
						{
							label: 'Index',
							url: '/orders',
						},
						{
							label: 'New',
							url: '/orders/new',
						},
					],
				},
				{
					permission: 'terminal_readers',
					Icon: CreditCard,
					label: 'Payment Terminal',
					url: '/terminal_readers',
					children: [
						{
							label: 'Index',
							url: '/terminal_readers',
						},
					],

				},
				{
					permission: 'applications',
					Icon: Notes,
					label: 'Applications',
					url: '/applications',
					children: [
						{
							label: 'Index',
							url: '/applications',
						},
					],
				},
				{
					permission: 'applications',
					Icon: PresentationAnalytics,
					label: <span>Panels <ExternalLink strokeWidth={1} size={16} style={{display: 'inline'}} /></span>,
					url: 'https://panels.holidaymatsuri.com/',
					external: true,
					children: [
						{
							label: 'Index',
							url: 'https://panels.holidaymatsuri.com/',
						}
					]
				},
				{
					permission: 'artists',
					Icon: Palette,
					label: 'Artists',
					url: '/artists',
					children: [
						{
							label: 'Index',
							url: '/artists',
						},
					],
				},
				{
					permission: 'guests',
					Icon: UserExclamation,
					label: 'Guests',
					url: '/guests',
					children: [
						{
							label: 'Index',
							url: '/guests',
						},
					],
				},
				{
					permission: 'vendors',
					Icon: BrandAirtable,
					label: 'Vendors',
					url: '/vendors',
					children: [
						{
							label: 'Index',
							url: '/vendors',
						},
					],
				},
				{
					permission: 'meals',
					Icon: Apple,
					label: 'Meals',
					url: '/meals',
					children: [
						{
							label: 'Index',
							url: '/meals',
						},
					],
				},
				{
					permission: 'users',
					Icon: Users,
					label: 'Users',
					url: '/users',
					children: [
						{
							label: 'Index',
							url: `/users`,
						},
					],
				},
				{
					permission: 'settings',
					Icon: Settings,
					label: 'Settings',
					url: `/users/${current_user.id}/account`,
					children: [
						{
							label: 'Index',
							url: `/users/${current_user.id}/account`,
						},
					],
				},
			].filter((page) => can_index[page.permission]),
		[current_user.id, can_index],
	)

	const trigger = useRef(null)
	const sidebar = useRef(null)

	const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
	const [sidebarExpanded, setSidebarExpanded] = useState(
		storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
	)

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }) => {
			if (!sidebar.current || !trigger.current) return
			if (!open || sidebar.current.contains(target) || trigger.current.contains(target)) return
			console.info(sidebar.current.contains(target), trigger.current.contains(target))
			console.info("Closing")
			console.info({open, target}, sidebar.current, trigger.current)
			// setOpen(false)
		}
		document.addEventListener('click', clickHandler)
		return () => document.removeEventListener('click', clickHandler)
	})

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!open || keyCode !== 27) return
			setOpen(false)
		}
		document.addEventListener('keydown', keyHandler)
		return () => document.removeEventListener('keydown', keyHandler)
	})

	useEffect(() => {
		localStorage.setItem('sidebar-expanded', sidebarExpanded)
		if (sidebarExpanded) {
			document.querySelector('body').classList.add('sidebar-expanded')
		} else {
			document.querySelector('body').classList.remove('sidebar-expanded')
		}
	}, [sidebarExpanded])

	return (
		<div>
			{/* Sidebar backdrop (mobile only) */}
			<div
				className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
					open ? 'opacity-100' : 'opacity-0 pointer-events-none'
				}`}
				aria-hidden="true"
			></div>
			{/* Sidebar */}
			<div
				id="sidebar"
				ref={sidebar}
				className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-emerald-800 p-4 transition-all duration-200 ease-in-out ${
					open ? 'translate-x-0' : '-translate-x-64'
				}`}
			>
				{/* Sidebar header */}
				<div className="flex justify-between mb-10 pr-3 sm:px-2">
					{/* Close button */}
					<button
						ref={trigger}
						className="lg:hidden text-slate-500 hover:text-slate-400"
						onClick={() => setOpen(!open)}
						aria-controls="sidebar"
						aria-expanded={open}
					>
						<span className="sr-only">Close sidebar</span>
						<svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
						</svg>
					</button>
					{/* Logo */}
					<NavLink end to="/" className="block">
						<img src={Logo} alt="Holiday Matsuri" />
					</NavLink>
				</div>

				{/* Links */}
				<div className="space-y-8">
					{/* Pages group */}
					<div>
						<h3 className="text-xs hidden uppercase text-slate-200 font-semibold pl-3">
							<span
								className="lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
								aria-hidden="true"
							>
								•••
							</span>
							<span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
						</h3>
						<ul className="mt-3">
							{pages.map((page) => (
								<Group key={page.url} activecondition={pathname.includes(page.url)}>
									{(handleClick, open) => (
										<React.Fragment>
											<Item
												{...page}
												sidebarExpanded={sidebarExpanded}
												setSidebarExpanded={setOpen}
												open={open}
												handleClick={setOpen}
												is_header
											/>
											{/* <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
													<ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
														{page.children.map((subpage) => (
															<Item key={subpage.url} {...subpage} />
														))}
													</ul>
												</div> */}
										</React.Fragment>
									)}
								</Group>
							))}
						</ul>
					</div>
				</div>

				{/* Expand / collapse button */}
				<div className="pt-3 lg:inline-flex 2xl:hidden justify-end mt-auto">
					<div className="px-3 py-2">
						<button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
							<span className="sr-only">Expand / collapse sidebar</span>
							<svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
								<path
									className="text-slate-400"
									d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
								/>
								<path className="text-slate-600" d="M3 23H1V1h2z" />
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

SideBar.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
}

export default memo(SideBar)
