import React, { useState, useMemo, useContext, useEffect } from 'react'

import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'
import { GET_CONVENTION, GET_CURRENT_CONVENTION } from 'queries/conventions'

const CurrentConventionContext = React.createContext({})

const useCurrentConvention = () => useContext(CurrentConventionContext).convention

function CurrentConventionProvider({ children }) {
	const [convention_id, setConventionId] = useState('')

	const {
		data: { current_convention: convention },
		loading,
	} = useQuery(GET_CURRENT_CONVENTION, {
		default_data: {
			current_convention: {}
		},
		channels: [convention_id],
	})

	useEffect(() => {
		if (convention && convention.id) {
			setConventionId(convention.id)
		}
	}, [convention.id])

	console.info({convention, loading})

	const value = useMemo(() => ({
		convention,
		loading,
		setConventionId,
	}), [convention, loading, setConventionId])

	return <CurrentConventionContext.Provider value={value}>{children}</CurrentConventionContext.Provider>
}

export default CurrentConventionProvider
export { CurrentConventionContext, useCurrentConvention }