import { gql } from '@apollo/client'
import { boothFragments } from 'queries/booths'

export const userFragments = gql`
	fragment UserData on User {
		id

		full_name
		first_name
		last_name
		preferred_name
		pronouns
		gamertag
		email
		phone
		company_name
		birthdate
		active

		created_at
		updated_at
	}
`

export const GET_CURRENT_USER = gql`
	query {
		current_user {
			id
			guest
			email
			phone
			website
			first_name
			last_name
			full_name
			preferred_name
			pronouns
			gamertag
			initials
			company_name
			birthdate
			is_registration
		}
	}
`

export const GET_USER = gql`
	query getUser($id: ID!) {
		user(id: $id) {
			...UserData
			user_groups {
				id
				name
			}
			department_roles {
				id
				department_id
				role_id
			}
		}
	}
	${userFragments}
`

export const CREATE_USER = gql`
	mutation createUser($input: CreateUserInput!) {
		createUser(input: $input) {
			user {
				...UserData
			}
		}
	}
	${userFragments}
`

export const UPDATE_USER = gql`
	mutation updateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				...UserData
			}
		}
	}
	${userFragments}
`

export const LOOKUP_USER = gql`
	query lookup_user(
		$order_number: String
		$email: String
		$phone: String
	) {
		lookup_user(
			order_number: $order_number
			email: $email
			phone: $phone
		)
	}
`

export const VERIFY_LOGIN = gql`
	mutation verifyLogin($input: VerifyLoginInput!) {
		verifyLogin(input: $input) {
			verification_last_sent
		}
	}
`

export const SEARCH_USERS = gql`
	query searchUsers(
		$page: Int
		$page_size: Int
		$sort: String
		$sort_direction: String
		$search: String
		$filters: JSON
		$user_id: ID
		$user_ids: [ID]
	) {
		search_users(
			page: $page
			page_size: $page_size
			sort: $sort
			sort_direction: $sort_direction
			search: $search
			filters: $filters
			user_id: $user_id
			user_ids: $user_ids
		) {
			users {
				...UserData
			}
		}
	}
	${userFragments}
`

export const GET_DEPARTMENTS = gql`
	query {
		departments {
			id
			name
		}
	}
`

export const GET_ROLES = gql`
	query {
		roles {
			id
			name
		}
	}
`

export const GET_USER_GROUPS = gql`
	query {
		user_groups
	}
`

export const GET_OUTSTANDING_BOOTHS = gql`
	query getOutstandingBooths($id: ID!) {
		user(id: $id) {
			id
			outstanding_booths {
				...BoothData
			}
		}
	}
	${boothFragments}
`

export const GET_OUTSTANDING_BOOTHS_CHECKOUT_SESSION_URL = gql`
	query getOutstandingBoothsCheckoutSessionURL($id: ID!) {
		user(id: $id) {
			id
			outstanding_booths_checkout_session_url
		}
	}
`

export const DECLINE_BOOTHS = gql`
    mutation declineBooths($input: DeclineBoothsInput!) {
        declineBooths(input: $input) {
            success
        }
    }
`

export const GET_MAGIC_LINK_URL = gql`
	query getMagicLinkUrl($user_id: ID!, $redirect_url: String, $expire_at: ISO8601DateTime) {
		magic_link_url(user_id: $user_id, redirect_url: $redirect_url, expire_at: $expire_at)
	}
`
