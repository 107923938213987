/*
 * /ThemeSwitch Messages
 *
 * This contains all the text for the ThemeSwitch component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.ThemeSwitch'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the ThemeSwitch component!',
	},
})
