/**
 *
 * /TestingBanner
 *
 */

import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

const Banner = styled.div`
	// position: absolute;
	z-index: 1000000;
	left: 0;
	top: 0;
	right: 0;

	padding: 0.25em 0.75em 0.4em;
`

function TestingBanner() {
	if (process.env.ENV === 'production') return null

	return (
		<Banner className="bg-amber-500 text-white text-center">
			<small><FormattedMessage {...messages.header} /></small>
		</Banner>
	)
}

TestingBanner.propTypes = {}

export default memo(TestingBanner)
