/**
 *
 * Users/CompleteProfile
 *
 */

import React, { memo, useEffect } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'

import { useCurrentUser } from 'currentUserContext'
import { useHandleInputChange } from '@joeyparis/hooks'
import { formatPhoneNumber } from '@joeyparis/utils'

import { useMutation } from '@apollo/client'
import { UPDATE_USER } from 'queries/users'

import FloatingLabel from 'components/FloatingLabel'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { toast } from 'react-toastify'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

function CompleteProfile({ onClose }) {

	const current_user = useCurrentUser()

	const [state, handleInputChange, setState, resetState] = useHandleInputChange({})

	useEffect(() => {
		if(current_user) {
			setState({
				first_name: current_user.first_name,
				last_name: current_user.last_name,
				preferred_name: current_user.preferred_name,
				pronouns: current_user.pronouns,
				gamertag: current_user.gamertag,
				email: current_user.email,
				phone: formatPhoneNumber(current_user.phone),
				company_name: current_user.company_name,
				website: current_user.website,
				birthdate: current_user.birthdate,
			})
		}
	}, [current_user])

	const [updateUser, { loading }] = useMutation(UPDATE_USER)

	const handleSubmit = (e) => {
		e.preventDefault()

		updateUser({
			variables: {
				input: {
					id: current_user.id,
					first_name: state.first_name,
					last_name: state.last_name,
					preferred_name: state.preferred_name,
					pronouns: state.pronouns,
					gamertag: state.gamertag,
					email: state.email,
					phone: state.phone,
					company_name: state.company_name,
					website: state.website,
					birthdate: state.birthdate,
				}
			}
		}).then(() => {
			toast.success('Profile Updated!')
			if(onClose) onClose()
		})
		
		return false
	}

	return (
		<form onSubmit={handleSubmit}>
			<Card className="mb-3">
				<CardHeader
					title="Complete Your Profile 🎁"
					subheader="Please fill out the information below to personalize your Holiday Matsuri experience!"
				/>
			</Card>
			{/* Name */}
			<Card className="mb-3">
				<CardContent>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						What should we call you?
					</Typography>
					<Grid container spacing={2}>
						<Grid item sm={6}>
							<FloatingLabel
								type="text"
								name="first_name"
								label="First Name"
								value={state.first_name}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item sm={6}>
							<FloatingLabel
								type="text"
								name="last_name"
								label="Last Name"
								value={state.last_name}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item sm={8}>
							<FloatingLabel
								type="text"
								name="preferred_name"
								label="Preferred Name"
								value={state.preferred_name}
								onChange={handleInputChange}
								placeholder={state.first_name}
								always_float={Boolean(state.first_name)}
							/>
						</Grid>
						<Grid item sm={4}>
							<FloatingLabel
								type="text"
								name="pronouns"
								label="Pronouns"
								value={state.pronouns}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item sm={12}>
							<FloatingLabel
								type="text"
								name="gamertag"
								label="Gamertag"
								value={state.gamertag}
								onChange={handleInputChange}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* Contact Info */}
			<Card className="mb-3">
				<CardContent>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						How should we contact you?
					</Typography>
					<Grid container spacing={2}>
						<Grid item sm={6}>
							<FloatingLabel
								type="email"
								name="email"
								label="Email"
								value={state.email}
								onChange={handleInputChange}
								disabled
								required
							/>
						</Grid>
						<Grid item sm={6}>
							<FloatingLabel
								type="tel"
								name="phone"
								label="Phone"
								value={state.phone}
								onChange={handleInputChange}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* More Info */}
			<Card className="mb-3">
				<CardContent>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						Tell us more about yourself!
					</Typography>
					<Grid container spacing={2}>
						<Grid item sm={6}>
							<FloatingLabel
								type="text"
								name="company_name"
								label="Company Name"
								value={state.company_name}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item sm={6}>
							<FloatingLabel
								type="url"
								name="website"
								label="Website"
								value={state.website}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item sm={6}>
							<FloatingLabel
								type="date"
								name="birthdate"
								label="Birthdate"
								value={state.birthdate}
								onChange={handleInputChange}
								always_float
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<div className="flex justify-between">
				{!onClose && <span />}
				{onClose && (
					<button
						className="btn uppercase text-white bg-gray-400"
						type="button"
						onClick={onClose}
					>
						Maybe Later
					</button>
				)}
				<Button type="submit" variant="contained" size="medium" color="primary">
					Save
				</Button>
			</div>
		</form>
	)
}

CompleteProfile.propTypes = {}

export default memo(CompleteProfile)
