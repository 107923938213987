/*
 * Sidebar/Group Messages
 *
 * This contains all the text for the Group component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.SidebarGroup'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the SidebarGroup component!',
	},
})
