/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { Suspense, createContext } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

import Switch from '@mui/material/Switch';

import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import 'sanitize.css/sanitize.css'
import 'assets/tailwind.css'
import 'tailwindcss/base.css'
import 'tailwindcss/components.css'
import 'tailwindcss/utilities.css'

import 'configureAxios'

import { useToggle } from '@joeyparis/hooks'

// Import root app
import App from 'components/App'

import history from 'utils/history'

// Import Language Provider
import LanguageProvider from 'providers/LanguageProvider'

// Import Apollo provider and client
import { ApolloProvider } from '@apollo/client'
import client from 'configureApolloClient'

import CurrentUserProvider from 'currentUserContext'
import CurrentConventionProvider from 'currentConventionContext'


import { library, dom } from '@fortawesome/fontawesome-svg-core'
import('@fortawesome/pro-regular-svg-icons').then(({ far }) => library.add(far))
import('@fortawesome/pro-solid-svg-icons').then(({ fas }) => library.add(fas))
import('@fortawesome/pro-light-svg-icons').then(({ fal }) => library.add(fal))
import('@fortawesome/free-brands-svg-icons').then(({ fab }) => library.add(fab))
dom.watch()

import { ThemeProvider } from '@mui/material/styles'

import { DarkTheme, LightTheme } from 'global-styles'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
/* eslint-enable import/no-unresolved */

import { HelmetProvider } from 'react-helmet-async'

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import ThemeSwitch from 'components/ThemeSwitch'
import TestingBanner from 'components/TestingBanner'

// Import i18n messages
import { translationMessages } from './i18n'

// Create redux store with history
const MOUNT_NODE = document.getElementById('app')

const ThemeContext = createContext(LightTheme)

const BuildNumber = styled.div`
	position: fixed;
	bottom: 0;
	left: 1em;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 0.5rem;
	padding: 0.25em 0.5em;
	z-index: 1000000;
`

const stripePromise = loadStripe('pk_test_51KTtPtJiQd4eMliCYUrFaSDKgRcJR75YJgsorGK4ngz3eKIyN5eMuRtNn4S83XgyXvpXHPHQOuR3UDuqoFYS9l23003uvGIHt6')


const ConnectedApp = (props) => {
	const [theme, toggleTheme] = useToggle(LightTheme, [DarkTheme, LightTheme])


	return (
		<Suspense fallback={null}>
			<Router history={history}>
				<LanguageProvider messages={props.messages}>
					<ApolloProvider client={client}>
						<Elements stripe={stripePromise}>
							<CurrentUserProvider>
								<CurrentConventionProvider>
									<HelmetProvider>
										<ThemeProvider theme={theme}>
											<TestingBanner />
											<App />
											<BuildNumber>{process.env.ENV[0].toUpperCase()}.{process.env.BUILD_NUMBER}</BuildNumber>
										</ThemeProvider>
									</HelmetProvider>
								</CurrentConventionProvider>
							</CurrentUserProvider>
						</Elements>
					</ApolloProvider>
				</LanguageProvider>
			</Router>
		</Suspense>
	)
}

ConnectedApp.propTypes = {
	messages: PropTypes.object,
}

const render = (messages) => {
	ReactDOM.render(<ConnectedApp messages={messages} />, MOUNT_NODE)
}

if (import.meta.webpackHot) {
	// Hot reloadable translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	import.meta.webpackHot.accept(['./i18n'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE)
		render(translationMessages)
	})
}

render(translationMessages)

// if (process.env.NODE_ENV === 'production') {
// 	if ('serviceWorker' in navigator) {
// 		window.addEventListener('load', () => {
// 			navigator.serviceWorker
// 				.register('/service-worker.js')
// 				.then((registration) => {
// 					console.log('SW registered: ', registration)
// 				})
// 				.catch((registrationError) => {
// 					console.log('SW registration failed: ', registrationError)
// 				})
// 		})
// 	}
// }

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
// 	OfflinePluginRuntime.install({
// 		onUpdating: () => {
// 			console.log('SW Event:', 'onUpdating')
// 		},
// 		onUpdateReady: () => {
// 			console.log('SW Event:', 'onUpdateReady')
// 			// Tells to new SW to take control immediately
// 			OfflinePluginRuntime.applyUpdate()
// 		},
// 		onUpdated: () => {
// 			console.log('SW Event:', 'onUpdated')
// 			// Reload the webpage to load into the new version
// 			window.location.reload()
// 		},

// 		onUpdateFailed: () => {
// 			console.log('SW Event:', 'onUpdateFailed')
// 		},
// 	})
// 	// require('offline-plugin/runtime').install() // eslint-disable-line global-require
// }
