/*
 * /TestingBanner Messages
 *
 * This contains all the text for the TestingBanner component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.TestingBanner'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'Thank you for your help beta testing the new Holiday Matsuri ticketing portal! Please remember the tickets on the testing portal are not valid for entry into the Holiday Matsuri convention.',
	},
})
