import { css } from '@emotion/react'

import BalletHarmony from 'fonts/BalletHarmony.ttf'

const fonts = {
	BalletHarmony,
}

const fontFaces = {}

Object.keys(fonts).forEach((font_name) => {
	fontFaces[font_name] = css`
		@font-face {
			font-family: ${font_name};
			src: url('${fonts[font_name]}') format('truetype');
		}
	`
})

export default fontFaces
