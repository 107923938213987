/**
 *
 * Sidebar/Group
 *
 */

import React, { memo, useState } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

const Group = ({ children, activecondition }) => {
	const [open, setOpen] = useState(activecondition)

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${activecondition && 'bg-emerald-900'}`}>
			{children(handleClick, open)}
		</li>
	)
}

Group.propTypes = {}

export default memo(Group)
