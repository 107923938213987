import axios from 'axios'

axios.defaults.baseUrl = process.env.API_URL
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'X-CSRF-Token'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.headers.common['X-HolMat-Front-End-Build-Number'] = process.env.BUILD_NUMBER

axios.interceptors.response.use(
	(response) => {
		if (response.headers['x-holmat-highest-front-end-build-number'])
			sessionStorage.setItem(
				'highest_front_end_build_number',
				response.headers['x-holmat-highest-front-end-build-number'],
			)
		return response
	},
	(error) => Promise.reject(error),
)
