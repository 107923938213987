/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { Suspense, lazy, useState, useMemo, useContext, useEffect } from 'react'
import usePermissionCheck from 'hooks/usePermissionCheck'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { Routes, Route, useNavigate, useLocation, useSearchParams } from 'react-router-dom'

import GlobalStyle from 'global-styles'

import TopBar from 'components/TopBar'
// import Sidebar from 'components/Mosaic/partials/Sidebar'
import Sidebar from 'components/SideBar'
import Header from 'components/Mosaic/partials/Header'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import 'components/Mosaic/css/style.scss'
import 'components/Mosaic/charts/ChartjsConfig'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Login = lazy(() => import('components/Login'))
const BoothMap = lazy(() => import('components/BoothMap'))
const Users = lazy(() => import('components/Users/Index'))
const Products = lazy(() => import('components/Products/Index'))
const Product = lazy(() => import('components/Products/Show'))
const Coupons = lazy(() => import('components/Coupons/Index'))
const Tickets = lazy(() => import('components/Tickets/Index'))
const Ticket = lazy(() => import('components/Tickets/Show'))
const Share = lazy(() => import('components/Shares/Show'))
const Orders = lazy(() => import('components/Orders/Index'))
const TerminalReaders = lazy(() => import('components/TerminalReaders/Index'))
const Order = lazy(() => import('components/Orders/Show'))
const Applications = lazy(() => import('components/Applications/Index'))
const ApplicationShow = lazy(() => import('components/Applications/Show'))
const ApplicationEdit = lazy(() => import('components/Applications/Edit'))
const ApplicationSubmissionIndex = lazy(() => import('components/Applications/Submission/Index'))
const ApplicationSubmissionShow = lazy(() => import('components/Applications/Submission/Show'))
const NewOrder = lazy(() => import('components/Orders/Cart'))
const OrderConfirmation = lazy(() => import('components/Orders/Confirmation'))
const OutstandingBooths = lazy(() => import('components/Orders/OutstandingBooths'))
const OutstandingBoothsDecline = lazy(() => import('components/Orders/OutstandingBooths/Decline'))
const Artists = lazy(() => import('components/Artists/Index'))
const Guests = lazy(() => import('components/Guests/Index'))
const Meals = lazy(() => import('components/Meals/Index'))
const RegistrationForm = lazy(() => import('components/RegistrationForm'))
const UserShow = lazy(() => import('components/Users/Show'))
const UserOrders = lazy(() => import('components/Users/Orders'))
const CharaChorder = lazy(() => import('components/CharaChorder'))
const CheckIn = lazy(() => import('components/Registration/CheckIn'))
const Surveys = lazy(() => import('components/Surveys'))
const RegistrationScanner = lazy(() => import('components/Registration/QrScanner'))
const CheckInConfirmation = lazy(() => import('components/Registration/CheckInConfirmation'))
const RegistrationMenu = lazy(() => import('components/Registration/Menu'))
const VendorRebook = lazy(() => import('components/VendorRebook'))

import { CurrentUserContext } from 'currentUserContext'

const HomePage = () => <span>Home</span>
const NotFoundPage = () => <span>NotFoundPage</span>

// Nice background color #1A2038

export const drawerWidth = 240

export const NavigationContext = React.createContext({})

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}))

const App = () => {
	const navigate = useNavigate()
	const { pathname, state: location_state } = useLocation()
	const [search_params] = useSearchParams()
	const { current_user, loading } = useContext(CurrentUserContext)

	const flash = useMemo(() => {
		if (search_params.get('flash')) {
			return JSON.parse(search_params.get('flash'))
		}
		return {}
	}, [search_params])

	useEffect(() => {
		if (flash.notice) {
			toast.success(flash.notice)
		}

		if (flash.alert) {
			toast.error(flash.alert)
		}

		if (flash.warden_message) {
			toast.success(flash.warden_message)
		}
	}, [flash])
	

	const can_index = {
		products: usePermissionCheck(current_user.id, 'index_products', 'User'),
		tickets: usePermissionCheck(current_user.id, 'index_tickets', 'User'),
		coupons: usePermissionCheck(current_user.id, 'index_coupons', 'User'),
		orders: usePermissionCheck(current_user.id, 'index_orders', 'User'),
		terminal_readers: usePermissionCheck(current_user.id, 'index_terminal_readers', 'User'),
		applications: usePermissionCheck(current_user.id, 'index_applications', 'User'),
		artists: usePermissionCheck(current_user.id, 'index_artists', 'User'),
		guests: usePermissionCheck(current_user.id, 'index_guests', 'User'),
		vendors: usePermissionCheck(current_user.id, 'index_vendors', 'User'),
		meals: usePermissionCheck(current_user.id, 'index_meals', 'User'),
		users: usePermissionCheck(current_user.id, 'index_users', 'User'),
		settings: usePermissionCheck(current_user.id, 'index_settings', 'User'),
	}

	const [open, setOpen] = useState(false)
	const open_state = useMemo(() => ({ open, setOpen }), [open, setOpen])

	// useEffect(() => {
	// 	if (current_user.id && !loading && pathname === '/login') {
	// 		navigate('/orders')
	// 	}
	// }, [current_user.id, loading, location.pathname])
	if (!current_user.id && loading) {
		return null
	}

	if ((current_user.guest && !loading)) {
		if (
			pathname == '/orders/new/vendor_booth' || (
				pathname !== '/' &&
				pathname !== '/login' &&
				pathname !== '/users/password/edit' &&
				!pathname.startsWith('/orders/new') &&
				!pathname.startsWith('/orders/success/') &&
				!pathname.startsWith('/shares/')
			)
		) {
			navigate('/login', {
				state: {requested_location: `${pathname}${window.location.search}${window.location.hash}`},
			})
		}
		return (
			<Suspense fallback={null}>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/login" element={<Login />} />
					<Route path="/users/password/edit" element={<Login />} />
					<Route path="/orders/new" element={<NewOrder />} />
					<Route path="/orders/new/:category" element={<NewOrder />} />
					<Route path="/orders/success/:checkout_session_id" element={<OrderConfirmation />} />
					<Route path="/shares/:id" element={<Share />} />
				</Routes>
				<ToastContainer />
				<Global styles={GlobalStyle} />
			</Suspense>
		)
	}

	if (pathname === '/login' && !current_user.guest && !loading) {
		console.info({location_state})
		if (location_state) {
			if (location_state.requested_location.startsWith('/oauth/authorize')) {
				window.location = `${process.env.API_URL}${location_state.requested_location}`
			} else {
				navigate(location_state.requested_location || '/registration/menu')
			}
		} else {
			navigate('/registration/menu')
		}
	}

	const show_sidebar = pathname !== '/orders/new' &&
		pathname !== '/charachorder' &&
		!pathname.startsWith('/registration/orders') &&
		pathname !== '/registration/menu'

	return (
		<NavigationContext.Provider value={open_state}>
			<div className="flex h-screen overflow-hidden">
				{show_sidebar && <Sidebar open={open} setOpen={setOpen} can_index={can_index} />}
				<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
					<Header sidebarOpen={open} setSidebarOpen={setOpen} />
					<main style={{height: '100%'}}>
						<Suspense fallback={null}>
							<Routes>
								<Route path="/" element={<Login />} />
								<Route path="/login" element={<Login />} />
								<Route path="/users/password/edit" element={<Login />} />
								<Route path="/vendors" element={<BoothMap />} />
								<Route path="/users" element={<Users />} />
								<Route path="/users/:id/account" element={<UserShow />} />
								<Route path="/users/:id/orders" element={<UserOrders />} />
								<Route path="/products" element={<Products />} />
								<Route path="/products/:id" element={<Product />} />
								<Route path="/coupons" element={<Coupons />} />
								<Route path="/tickets" element={<Tickets />} />
								<Route path="/vendor_rebook" element={<VendorRebook />} />
								{/* <Route path="/tickets/:id" element={<CheckIn />} /> */}
								<Route path="/shares/:id" element={<Share />} />
								<Route path="/orders" element={<Orders />} />
								<Route path="/orders/new" element={<NewOrder />} />
								<Route path="/orders/new/:category" element={<NewOrder />} />
								<Route path="/orders/success/:checkout_session_id" element={<OrderConfirmation />} />
								<Route path="/orders/outstanding/booths" element={<OutstandingBooths />} />
								<Route path="/orders/outstanding/booths/decline" element={<OutstandingBoothsDecline />} />
								<Route path="/orders/:id" element={<Order />} />
								<Route path="/terminal_readers" element={<TerminalReaders />} />
								<Route path="/applications" element={<Applications />} />
								<Route path="/applications/submissions" element={<ApplicationSubmissionIndex />} />
								<Route path="/applications/:slug/submissions" element={<ApplicationSubmissionIndex />} />
								<Route path="/applications/submissions/:id" element={<ApplicationSubmissionShow />} />
								<Route path="/applications/:slug" element={<ApplicationShow />} />
								<Route path="/applications/:id/edit" element={<ApplicationEdit />} />
								<Route path="/artists" element={<Artists />} />
								<Route path="/guests" element={<Guests />} />
								<Route path="/meals" element={<Meals />} />
								<Route path="/surveys" element={<Surveys />} />
								<Route path="/registration" element={<RegistrationForm />} />
								<Route path="/registration/menu" element={current_user.is_registration ? <RegistrationMenu /> : <NewOrder />} />
								<Route path="/registration/check_in" element={<CheckIn />} />
								<Route path="/registration/:type/:id" element={<CheckInConfirmation />} />
								<Route path="/charachorder" element={<CharaChorder />} />
								<Route path="*" element={<NotFoundPage />} />
							</Routes>
							<ToastContainer 
								position="top-right"
								autoClose={5000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
							/>
							<Global styles={GlobalStyle} />
						</Suspense>
					</main>
				</div>
			</div>
		</NavigationContext.Provider>
	)
}

export default App
