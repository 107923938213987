import io from 'socket.io-client'
import axios from 'axios'

export default class Socket {
	constructor() {
		if (global.testing) return {}

		if (!window.socket) {
			// Initialize the socket
			if (window.location.host.includes('127.0.0.1') || window.location.host.includes('localhost'))
				window.socket = io.connect('http://localhost:8080')
			else window.socket = io.connect('https://socket.leadjig.com')

			window.socket.requested_channels = {}
			window.socket.join = this.join
			window.socket.leave = this.leave
			window.socket.joinIndividualChannel = this.joinIndividualChannel
			window.socket.leaveIndividualChannel = this.leaveIndividualChannel

			window.socket.on('connect', () => {
				console.info('Connected')

				// axios({
				// 	method: 'GET',
				// 	url: `${process.env.API_URL}/api/v1/users/current_user_id`,
				// })
				// 	.then((response) => {
				// 		window.socket.emit('join', response.data)
				// 	})
				// 	.catch((err) => {
				// 		console.error(err)
				// 	})
			})
		}

		return window.socket
	}

	join(channels, context) {
		if (!Array.isArray(channels)) channels = [channels] // eslint-disable-line no-param-reassign

		channels.forEach((c) => this.joinIndividualChannel(c, context))
	}

	leave(channels, context) {
		if (!Array.isArray(channels)) channels = [channels] // eslint-disable-line no-param-reassign

		channels.forEach((c) => this.leaveIndividualChannel(c, context))
	}

	joinIndividualChannel(channel, context) {
		// Initialize the channel's requesters set
		if (!this.requested_channels[channel]) this.requested_channels[channel] = new Set()

		// Add the context to the channel's requesters set
		this.requested_channels[channel].add(context)

		// Join the channel generously
		window.socket.emit('join', channel)
	}

	leaveIndividualChannel(channel, context) {
		if (this.requested_channels[channel]) {
			// Remove the context from the requesters set
			this.requested_channels[channel].delete(context)

			// Only leave the channel if it's the last requester context in the set
			if (this.requested_channels[channel].size === 0) window.socket.emit('leave', channel)
		}
	}
}
