import { gql } from '@apollo/client'

export const boothFragments = gql`
    fragment BoothData on Booth {
        id
        table_number
        room
        booth_type
        sponsored
        is_paid
        is_overdue
        payment_deadline
        price
        is_taken
        user_id
        product_variation_id
        convention_id
    }
`

export const GET_BOOTH = gql`
    query getBooth($table_number: String!, $room: String!, $convention_id: ID!) {
        booth(table_number: $table_number, room: $room, convention_id: $convention_id) {
            ...BoothData
            user {
                id
                email
                company_name
            }
            shared_booths {
                id
                table_number
            }
            group {
                id
                table_number
            }
        }
    }
    ${boothFragments}
`

export const UPDATE_BOOTH = gql`
    mutation updateBooth($input: UpdateBoothInput!) {
        updateBooth(input: $input) {
            booth {
                ...BoothData
            }
        }
    }
    ${boothFragments}
`

export const ASSIGN_VENDOR_TO_BOOTH = gql`
    mutation assignVendorToBooth($input: AssignVendorToBoothInput!) {
        assignVendorToBooth(input: $input) {
            booth {
                ...BoothData
            }
        }
    }
    ${boothFragments}
`

export const GET_VENDORS = gql`
    query getVendors($convention_id: ID!) {
        vendors(convention_id: $convention_id) {
            id
            email
            company_name
            booths {
                id
                table_number
            }
        }
    }
`

export const GET_BOOTH_PRODUCTS = gql`
    query getProducts($convention_id: ID!) {
        products(convention_id: $convention_id, category: "vendor_booth") {
            id
            name
            product_variations {
                id
                name
                current_tier {
                    id
                    price
                }
            }
        }
    }
`
