import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { makeThrottle } from '@joeyparis/utils'

// if (process.env.ENV !== 'development') {
	const throttles = {}

	Sentry.init({
		dsn: 'https://631acb2240b143449d81cf36d0e3bb1d@o152289.ingest.sentry.io/6359887',
		release: `${process.env.ENV[0]}.${process.env.BUILD_NUMBER}`,
		environment: process.env.ENV,
		attachStacktrace: true,
		integrations: [new Integrations.BrowserTracing()],
		beforeSend: (event, hint) => {
			if (hint && hint.originalException && hint.originalException.message) {
				const { message } = hint.originalException

				if (!throttles[message]) throttles[message] = makeThrottle()

				if (throttles[message]()) {
					console.info('Not sending event to Sentry')
					return null
				}
			}

			console.info('Sending event to Sentry')
			return event
		},
	})

	Sentry.setTag('version_label', `holmat-${process.env.ENV[0]}.${process.env.BUILD_NUMBER}`)
// }

export default Sentry
