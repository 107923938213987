/*
 * Users/CompleteProfile Messages
 *
 * This contains all the text for the CompleteProfile component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.UsersCompleteProfile'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the UsersCompleteProfile component!',
	},
})
