/*
 *
 * LanguageProvider
 *
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import create from 'zustand'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'locales'

import messages from 'translations/translations'

const FORMATS = {
	number: {
		USD: {
			style: 'currency',
			currency: 'USD',
		},
	},
}

export const useLanguageStore = create((set) => ({
	language: '',
	locale: DEFAULT_LOCALE,
	setLanguage: (language) => set({ language }),
	setLocale: (locale) => set({ locale }),
}))

export const LanguageProviderContext = createContext({})

const LanguageProvider = (props) => {
	const locale = useLanguageStore((s) => s.locale)
	const setLocale = useLanguageStore((s) => s.setLocale)

	const language = useLanguageStore((s) => s.language)
	const setLanguage = useLanguageStore((s) => s.setLanguage)
	const [translation, setTranslation] = useState({})

	useEffect(() => {
		if (messages[window.navigator.language]) {
			setLocale(window.navigator.language)
		}
	}, [window.navigator.language])

	useEffect(() => {
		setLanguage(messages[locale])
		setTranslation(messages[locale])
	}, [locale])

	return (
		<IntlProvider
			locale={locale}
			messages={{ ...props.messages[locale], ...language, ...translation }}
			formats={FORMATS}
			defaultFormats={FORMATS}
		>
			{React.Children.only(props.children)}
		</IntlProvider>
	)
}

export default LanguageProvider

LanguageProvider.propTypes = {
	messages: PropTypes.object,
	children: PropTypes.element.isRequired,
}
