import { gql } from '@apollo/client'

export const conventionFragments = gql`
	fragment ConventionData on Convention {
		id
		name
		start_date
		end_date
		venue
		address_components
		address_formatted
	}
`

export const GET_CONVENTION = gql`
	query getConvention($id: ID!) {
		convention(id: $id) {
			...ConventionData
		}
	}
	${conventionFragments}
`

export const GET_CURRENT_CONVENTION = gql`
	query getCurrentConvention {
		current_convention {
			...ConventionData
		}
	}
	${conventionFragments}
`
