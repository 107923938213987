import { css } from '@emotion/react'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
// import 'react-table/react-table.css'
import('assets/react_select_search.css')

import Fonts from './fonts'

const GlobalStyle = css`
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	html,
	body,
	#app {
		height: 100%;
		width: 100%;
	}

	body {
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	}

	#app {
		min-height: 100%;
		min-width: 100%;
	}

	input,
	select {
		font-family: inherit;
		font-size: inherit;
	}

	${Fonts.BalletHarmony}
`

export const LightTheme = createMuiTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#2e7d32',
		},
		secondary: {
			main: '#dc0409',
		},
	},
})

export const DarkTheme = createMuiTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#3fb346',
			light: 'rgb(44, 125, 49)',
		},
		secondary: {
			main: '#d61e23',
		},
	},
})

export default GlobalStyle
