/**
 *
 * Sidebar/Item
 *
 */

import React, { memo } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useLocation, NavLink } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

const Item = ({ url, external, Icon, label, sidebarExpanded, setSidebarExpanded, handleClick, open, is_header }) => {
	const { pathname } = useLocation()

	if (is_header) {
		return (
			<NavLink
				to={url}
				className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
					pathname.includes(url) && 'hover:text-slate-200'
				}`}
				onClick={(e) => {
					// e.preventDefault()
					if (external) {
						window.location = url
					}
					sidebarExpanded ? handleClick() : setSidebarExpanded(true)
				}}
			>
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<Icon />
						<span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
							{label}
						</span>
					</div>
					{/* Icon */}
					{/*<div className="flex shrink-0 ml-2">
						<svg
							className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-200 ${
								open && 'transform rotate-180'
							}`}
							viewBox="0 0 12 12"
						>
							<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
						</svg>
					</div>*/}
				</div>
			</NavLink>
		)
	}

	return (
		<li className="mb-1 last:mb-0">
			<NavLink
				end
				to={() => {
					setSidebarExpanded(false)
					return url
				}}
				className={({ isActive }) => `block text-slate-300 hover:text-slate-200 transition duration-150 truncate ${isActive ? '!text-emerald-500' : ''}`}
			>
				<span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
					{label}
				</span>
			</NavLink>
		</li>
	)
}

Item.propTypes = {}

export default memo(Item)
