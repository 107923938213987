/**
 *
 * /UseQuery
 *
 */

// todo: Move to hook, not component

import { useEffect, useRef } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'

// import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
// import { Mutation } from 'react-apollo'
import { debounce } from 'debounce'

import IO from 'components/Socket/IO'

// import Sentry from 'configureSentry'

import { useIdentifier } from '@joeyparis/hooks'

function UseQuery(QUERY, props = {}) {
	const { channels = [], default_data = {}, refetch_on_mount = false, ...args } = props

	const can_skip = false // todo: take from prop, then add will_skip, add queue of queries to run when other queries haven't been run for a little bit. The link defer

	const query = useQuery(QUERY, { skip: can_skip, ...args })
	query.data = { ...default_data, ...query.data }

	if (
		query.error &&
		query.error.networkError &&
		query.error.networkError.message.includes('Received status code 404')
	) {
		query.data = {}
	}
	const identifier = useIdentifier()

	const refetch = useRef()
	if (!refetch.current) {
		refetch.current = debounce(
			(channel) => {
				console.info(`Refetch executed ${channel}`)
				try {
					query?.refetch() // Sometimes if we navigate away quick the query is removed, before the debounce takes effect
				} catch (e) {
					console.error(e)
					// if (e.message !== "Cannot read property 'refetch' of undefined") {
					// 	throw e
					// } else {
					// 	// Sentry.captureException(e)
					// }
				}
			},
			500,
			false,
		)
	}

	useEffect(() => {
		const io = new IO()
		io.join(channels, identifier)
		channels.forEach((channel) =>
			io.on(channel, () => {
				console.info(`Refetch requested ${channel}`)
				refetch.current(channel)
			}),
		)

		return () => {
			io.leave(channels, identifier)
		}
	}, [channels, identifier])

	useEffect(() => {
		if (refetch_on_mount && !args.skip && refetch.current) {
			refetch.current('remount')
		}
	}, [])

	// If data is undefined because of an Apollo bug default to an empty
	// object
	if (typeof query.data === 'undefined') query.data = {}

	return query
}

export default UseQuery
